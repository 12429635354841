const  homeGridItems = [
    {
        name: "Ajouter un client",
        icon: "person_add",
        url: "/clients",
        color: "primary"
    },
    {
        name: "Planing de réservations",
        icon: "event_note",
        url: "/reservations/planing",
        color: "primary"
    },
    {
        name: "Créer une occupation",
        icon: "hotel",
        url: "/bedrooms",
        color: "primary"
    },
    {
        name: "Historique des occupations",
        icon: "hotel",
        url: "/occupations/history",
        color: "primary"
    },
    {
        name: "Prendre une réservation",
        icon: "today",
        url: "/reservations/new",
        color: "primary"
    },
    {
        name: "Personnel",
        icon: "person",
        url: "#",
        color: "primary"
    },
    {
        name: "Réservations en attente",
        icon: "hotel",
        url: "/reservations",
        color: "warning",
        count: "0"
    },
    {
        name: "Occupations en attente",
        icon: "hotel",
        url: "/occupations",
        color: "warning",
        count: "0"
    },
    {
        name: "Chambres occupées",
        icon: "hotel",
        url: "/bedrooms/occuped",
        color: "warning",
        count: "0"
    },
    {
        name: "Chambres libres",
        icon: "hotel",
        url: "/bedrooms/empty",
        color: "warning",
        count: "21"
    }
];

export  default homeGridItems;