import React from 'react';

class DateTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date()
        };
    }
    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    tick() {
        this.setState({
            time: new Date()
        });
    }
    render() {
        return (
            <div className="datetime-container">
                <div className="date text-right">{this.state.time.toLocaleString("fr-FR", {
                    weekday: 'long',
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                })}</div>
                <div className="time text-right">{this.state.time.toLocaleString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit"
                })}</div>
            </div>
        );
    }
}

export default DateTime;
