import React from 'react';
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import {Link} from "react-router-dom";
import MaterialIcon from "./MaterialIcon";

class HomeGridItem extends React.Component {
    render() {
        return (
            <Card color={this.props.item.color} className="hgi text-center text-white">
                <Link to={this.props.item.url}>
                    <CardBody>
                        <div className="hgi-icon d-flex">
                            <MaterialIcon icon={this.props.item.icon}/>
                        </div>
                        <div className="hgi-name">{this.props.item.name}</div>
                        {
                            this.props.item.count ?
                                <span className="hgi-notification">{this.props.item.count}</span> : ''
                        }
                    </CardBody>
                </Link>
            </Card>
        );
    }
}

export default HomeGridItem;
