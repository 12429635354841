import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import Col from "reactstrap/es/Col";
import AppWidget from "../../../components/Widgets/AppWidget";
import logo from "../../../assets/images/logo.png";
import {FormGroup, Row} from "reactstrap";
import Label from "reactstrap/es/Label";
import {Link} from "react-router-dom";

const title = 'Mon profile';

class Profile extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div id="page-profile">
                            <Row>
                                <Col md="8">
                                    <AppWidget/>
                                </Col>
                                <Col md="4">
                                    <div className="user-details">
                                        <div className="text-center">
                                            <img height={100} className="avatar" src={logo} alt=""/>
                                        </div>
                                        <FormGroup>
                                            <Label>Nom(s) et prénom(s)</Label>
                                            <div className="form-control-plaintext">Frank Boris</div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <div className="form-control-plaintext">frank@gmail.com</div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Téléphone</Label>
                                            <div className="form-control-plaintext">698789545</div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Sexe</Label>
                                            <div className="form-control-plaintext">Homme</div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Rôle</Label>
                                            <div className="form-control-plaintext">Receptioniste</div>
                                        </FormGroup>
                                    </div>
                                    <Link to="/login" className="btn btn-primary btn-block btn-lg">Se déconnecter</Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Profile;
