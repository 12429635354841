import React, {useState} from 'react';
import {Nav, Navbar, NavItem} from 'reactstrap';
import Container from "reactstrap/es/Container";
import {Link} from "react-router-dom";
import logo from '../../assets/images/default_hotel_logo.png';
import Button from "reactstrap/es/Button";
import DateTime from "../Widgets/DateTime";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ListGroup from "reactstrap/es/ListGroup";
import MaterialIcon from "../Widgets/MaterialIcon";

const Header = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Navbar className="toolbar" color="primary" dark expand="lg" fixed="top">
                <Container>
                    <div className="mr-auto d-flex">
                        <Link to="/" className="navbar-brand d-flex align-items-center">
                            <img className="hotel-logo" height={40} src={logo} alt=""/> <span className="hotel-name">Mon Hotel</span>
                        </Link>
                    </div>
                    <div className="ml-auto">
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <DateTime/>
                            </NavItem>
                            <NavItem>
                                <Button onClick={toggle} className="d-flex btn-user-menu align-items-center">
                                    <MaterialIcon classes="fa-2x" icon="&#xe7fd;"/>
                                    <div className="user-name">
                                        <span className="d-block">Utilisateur</span>
                                        <div className="text-warning">Frank Boris</div>
                                    </div>
                                    <MaterialIcon classes="text-primary fa-2x" color='primary' icon="&#xe8ac;"/>
                                </Button>
                            </NavItem>
                            <NavItem>
                                <Link to="/settings" className="btn btn-secondary btn-setting d-flex">
                                    <MaterialIcon style={{fontSize: '38px'}} classes="fa-2x" icon="&#xE8B8;"/>
                                </Link>
                            </NavItem>
                        </Nav>
                    </div>
                </Container>
            </Navbar>
            <Modal className="primary" isOpen={modal} toggle={toggle} backdrop={true} centered size="sm">
                <ModalHeader toggle={toggle}>Menu</ModalHeader>
                <ListGroup className="user-menu">
                    <Link to="/profile" className="list-group-item list-group-item-action">
                        Mon profile
                    </Link>
                    <Link to="/login" className="list-group-item list-group-item-action">
                        Déconnexion
                    </Link>
                    <Link to="/occupations/history" className="list-group-item list-group-item-action">
                        Mon historique
                    </Link>
                </ListGroup>
            </Modal>
        </div>
    );
};

export default Header;
