import React from 'react';
import {Helmet} from "react-helmet";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Input from "reactstrap/es/Input";
import Button from "reactstrap/es/Button";
import Table from "reactstrap/es/Table";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import clientItems from "../../../datas/clientItems";

const title = 'Gestion des clients';

class Client extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div id="page-client">
                            <form action="">
                                <div className="d-flex justify-content-between">
                                    <div className="form-block">
                                        <FormGroup>
                                            <Label for="code">Code</Label>
                                            <Input disabled readOnly value="20200327CLT1" type="text" name="code"
                                                   id="code"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Nom complet</Label>
                                            <Input type="text" name="name" id="name"
                                                   placeholder="Nom(s) et Prénom(s)"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="stat_mat">Statut matrimoniale</Label>
                                            <Input type="text" name="stat_mat" id="stat_mat"
                                                   placeholder="Statut matrimoniale"/>
                                        </FormGroup>
                                    </div>
                                    <div className="form-block">
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input type="email" name="email" id="email"
                                                   placeholder="Adresse email"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="telephone">Téléphone</Label>
                                            <Input type="tel" name="telephone" id="telephone"
                                                   placeholder="Numéro de téléphone"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="cni_number">N°CNI/Passport</Label>
                                            <Input type="text" name="cni_number" id="cni_number"
                                                   placeholder="N°CNI ou N° Passport"/>
                                        </FormGroup>
                                    </div>
                                    <div className="form-block">
                                        <FormGroup>
                                            <Label for="pays">Pays de résdence</Label>
                                            <Input type="text" name="pays" id="pays"
                                                   placeholder="Pays de résdence"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="ville">Ville</Label>
                                            <Input type="text" name="ville" id="ville"
                                                   placeholder="Ville de résdence"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="adresse">Adresse complète</Label>
                                            <Input type="text" name="adresse" id="adresse"
                                                   placeholder="Adresse complète"/>
                                        </FormGroup>
                                    </div>
                                </div>
                                <FormGroup className="text-center mb-3 mt-3 form-actions">
                                    <Button type="button" color="success">Ajouter</Button>{' '}
                                    <Button type="button" color="primary">Reinitialiser</Button>
                                </FormGroup>
                            </form>

                            <div className="d-flex justify-content-between">
                                <div className="t-search-input">
                                    <input className="form-control" placeholder="rechercher..." type="text"/>
                                    <i className="fa fa-search"/>
                                </div>

                                <div className="t-pagination">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <Button color="secondary">
                                                <i className="fa fa-chevron-left"/>
                                            </Button>
                                        </InputGroupAddon>
                                        <span className="form-control-plaintext">1 / 1</span>
                                        <InputGroupAddon addonType="append">
                                            <Button color="secondary">
                                                <i className="fa fa-chevron-right"/>
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="table-responsive primary">
                                <Table striped >
                                    <thead>
                                    <tr>
                                        <th>Code client</th>
                                        <th>Nom complet</th>
                                        <th>Téléphone</th>
                                        <th>CNI/Passport</th>
                                        <th>Pays</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        clientItems.map((client, key) => {
                                            return (
                                                <tr key={key}>
                                                    <th scope="row">{client.code}</th>
                                                    <td>{client.name}</td>
                                                    <td>{client.telephone}</td>
                                                    <td>{client.cni_number}</td>
                                                    <td>{client.pays}</td>
                                                    <td>
                                                        <Button size="sm" color="primary"><i
                                                            className="fa fa-eye"/></Button>{' '}
                                                        <Button size="sm" color="success"><i
                                                            className="fa fa-edit"/></Button>{' '}
                                                        <Button size="sm" color="danger"><i
                                                            className="fa fa-trash"/></Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Client;
