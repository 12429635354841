import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import Input from "reactstrap/es/Input";
import Button from "reactstrap/es/Button";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import FormGroup from "reactstrap/es/FormGroup";
import Table from "reactstrap/es/Table";

const title = 'Historique des occupations';

class OccupationHistory extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div className="page-occupations" id="page-occupation-list">
                            <Row xs="2" md="4">
                                <Col>
                                    <FormGroup>
                                        <Input/>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input/>
                                            <InputGroupAddon addonType="append">
                                                <Button color="danger">
                                                    <i className="fa fa-close"/>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input/>
                                            <InputGroupAddon addonType="append">
                                                <Button color="danger">
                                                    <i className="fa fa-close"/>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input/>
                                            <InputGroupAddon addonType="append">
                                                <Button color="danger">
                                                    <i className="fa fa-close"/>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="billing-state text-center">
                                <div className="billing d-inline-block">TOTAL : <span className="amount">0</span> FCFA</div>
                                <div className="billing d-inline-block">ENCAISSER : <span className="amount">0</span> FCFA</div>
                                <div className="billing d-inline-block">DETTE : <span className="amount">0</span> FCFA</div>
                            </div>
                            <div className="table-responsive primary">
                                <Table striped>
                                    <thead>
                                    <tr>
                                        <th>Heure</th>
                                        <th>Chambre</th>
                                        <th>Montant</th>
                                        <th>Réduction</th>
                                        <th>Montant N.A.P</th>
                                        <th>Etat</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>10:02:12</td>
                                        <td>CHAMBRE 101</td>
                                        <td>12 500</td>
                                        <td>0</td>
                                        <td>12 500</td>
                                        <td><span className="badge badge-success">Terminée</span></td>
                                        <td><Button size="sm" color="primary"><i className="fa fa-eye"/></Button></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default OccupationHistory;
