import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import ButtonGroup from "reactstrap/es/ButtonGroup";
import Button from "reactstrap/es/Button";
import {Link} from "react-router-dom";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Input from "reactstrap/es/Input";
import bedroom from "../../../assets/images/bedroom.png"
import MaterialIcon from "../../../components/Widgets/MaterialIcon";

const title = 'Ajout d\'une occupation';

class OccupationNew extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div className="page-occupations" id="page-occupation-new">
                            <div className="text-right mb-3">
                                <ButtonGroup>
                                    <Link to="/" className="rounded-0 btn btn-secondary">Occupation</Link>
                                    <Button disabled className="rounded-0 btn btn-outline-secondary">Encaissement</Button>
                                </ButtonGroup>
                            </div>
                            <div className="d-flex page-block">
                                <div className="block-left">
                                    <img className="bedroom-img" src={bedroom} alt=""/><br/>
                                    <Button color="warning" className="text-white btn-price">
                                        <div>Tarif TTC : </div>
                                        <div>0.0 Fcfa <MaterialIcon icon="edit"/></div>
                                    </Button>
                                </div>
                                <div className="block-right flex-fill">
                                    <form action="">
                                        <div className="d-flex justify-content-between">
                                            <div className="form-block">
                                                <FormGroup>
                                                    <Label for="code">Code</Label>
                                                    <Input disabled readOnly value="20200327CLT1" type="text" name="code" id="code"/>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="bedroom">Chambre</Label>
                                                    <Input type="text" name="bedroom" id="bedroom" placeholder="Chambre"/>
                                                    <MaterialIcon icon="expand_more"/>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="formule">Formule</Label>
                                                    <Input type="text" name="formule" id="formule" placeholder="Formule"/>
                                                    <MaterialIcon icon="expand_more"/>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="client">Client</Label>
                                                    <Input type="text" name="client" id="client" placeholder="Client"/>
                                                    <MaterialIcon icon="expand_more"/>
                                                </FormGroup>
                                            </div>
                                            <div className="form-block">
                                                <FormGroup>
                                                    <Label for="begin_date">Date d'arrivée</Label>
                                                    <Input type="text" name="begin_date" id="begin_date" placeholder="Pays de résdence"/>
                                                    <MaterialIcon icon="event"/>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="begin_hour">Heure d'arrivée</Label>
                                                    <Input type="text" name="begin_hour" id="begin_hour" placeholder="Ville de résdence"/>
                                                    <MaterialIcon icon="query_builder"/>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="end_date">Date départ</Label>
                                                    <Input type="text" name="end_date" id="end_date" placeholder="Adresse complète"/>
                                                    <MaterialIcon icon="event"/>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="end_hour">Heure départ</Label>
                                                    <Input type="text" name="end_hour" id="end_hour" placeholder="Adresse complète"/>
                                                    <MaterialIcon icon="query_builder"/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <FormGroup className="text-center mb-3 mt-3 form-actions">
                                            <Button type="button" color="success">Enregistrer</Button>{' '}
                                            <Button type="button" color="primary">Reinitialiser</Button>
                                        </FormGroup>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default OccupationNew;
