import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import {Link} from "react-router-dom";

const title = 'Liste des chambres occupées';

class BedroomOccuped extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div id="page-bedroom-list" className="d-flex">
                            <div className="sidebar">
                                <ul className="sidebar-menu">
                                    <li className="sbm-item">
                                        <span className="sbm-item-name">Etage 1</span> <span
                                        className="sbm-item-count">1</span>
                                    </li>
                                    <li className="sbm-item active">
                                        <span className="sbm-item-name">Etage 2</span> <span
                                        className="sbm-item-count">1</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="bedroom-container flex-fill">
                                <div className="row bedroom-list">
                                    <div className="col-md-4 bl-item bl-occuped">
                                        <Link to="/occupations/edit">
                                            <div className="card bg-warning mb-3">
                                                <div className="card-body d-flex text-white">
                                                    <h5 className="card-title flex-fill">Card title</h5>
                                                    <div className="bl-item-time text-right">08:15</div>
                                                    <div className="bl-item-client text-right">Frank Boris</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default BedroomOccuped;
