import Login from "../views/Auth/Login";
import Home from "../views/Private/Home/Home";
import Client from "../views/Private/Client/Client";
import Profile from "../views/Private/Profile/Profile";
import BedroomList from "../views/Private/Bedroom/BedroomList";
import OccupationNew from "../views/Private/Occupation/OccupationNew";
import OccupationEdit from "../views/Private/Occupation/OccupationEdit";
import OccupationInvoice from "../views/Private/Occupation/OccupationInvoice";
import ReservationNew from "../views/Private/Reservation/ReservationNew";
import ReservationEdit from "../views/Private/Reservation/ReservationEdit";
import ReservationList from "../views/Private/Reservation/ReservationList";
import OccupationList from "../views/Private/Occupation/OccupationList";
import BedroomEmpty from "../views/Private/Bedroom/BedroomEmpty";
import BedroomOccuped from "../views/Private/Bedroom/BedroomOccuped";
import OccupationHistory from "../views/Private/Occupation/OccupationHistory";
import Setting from "../views/Private/Profile/Setting";
import ReservationCalendar from "../views/Private/Reservation/ReservationCalendar";
import OccupationDetails from "../views/Private/Occupation/OccupationDetails";

const indexRoutes = [
    {
        path: "/login",
        component: Login
    },
    {
        path: "/clients",
        component: Client
    },
    {
        path: "/settings",
        component: Setting
    },
    {
        path: "/profile",
        component: Profile
    },
    {
        path: "/bedrooms/occuped",
        component: BedroomOccuped
    },
    {
        path: "/bedrooms/empty",
        component: BedroomEmpty
    },
    {
        path: "/bedrooms",
        component: BedroomList
    },
    {
        path: "/reservations/new",
        component: ReservationNew
    },
    {
        path: "/reservations/edit",
        component: ReservationEdit
    },
    {
        path: "/reservations/planing",
        component: ReservationCalendar
    },
    {
        path: "/reservations",
        component: ReservationList
    },
    {
        path: "/occupations/details",
        component: OccupationDetails
    },
    {
        path: "/occupations/new",
        component: OccupationNew
    },
    {
        path: "/occupations/edit",
        component: OccupationEdit
    },
    {
        path: "/occupations/invoice",
        component: OccupationInvoice
    },
    {
        path: "/occupations/history",
        component: OccupationHistory
    },
    {
        path: "/occupations",
        component: OccupationList
    },
    {
        path: "/employes",
        component: BedroomList
    },
    {
        path: "/",
        component: Home
    }
];

export default indexRoutes;
