import React from 'react';
import Container from "reactstrap/es/Container";
import {FormGroup, Input, Row} from "reactstrap";
import Form from "reactstrap/es/Form";
import Col from "reactstrap/es/Col";
import {Helmet} from "react-helmet";
import hotel_logo from "../../assets/images/default_hotel_logo.png";
import Label from "reactstrap/es/Label";
import {Link} from "react-router-dom";
import MaterialIcon from "../../components/Widgets/MaterialIcon";
import AppWidget from "../../components/Widgets/AppWidget";

class Login extends React.Component {

    render() {
        return (
            <div id="page-login">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | Connexion</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <Row>
                            <Col md="8">
                                <AppWidget/>
                            </Col>
                            <Col md="4" className="d-flex align-items-center">
                                <div className="loginForm">
                                    <div className="text-center">
                                        <img className="hotel-logo" src={hotel_logo} alt="Mon Hotel"/>
                                    </div>
                                    <Form>
                                        <FormGroup>
                                            <Label>Adresse email</Label>
                                            <Input type="text" placeholder="Adresse email"/>
                                        </FormGroup>
                                        <FormGroup className="mb-4">
                                            <Label>Mot de passe</Label>
                                            <Input type="text" placeholder="Mot de passe"/>
                                            <MaterialIcon icon="visibility_off"/>
                                        </FormGroup>
                                        <FormGroup className="clearfix mb-0">
                                            <Link to="/" block type="button" className="btn btn-primary btn-block"
                                                  color="primary">Connexion</Link>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Login;
