import React from 'react';

import homeGridItems from '../../../datas/homeItems';
import HomeGridItem from "../../../components/Widgets/HomeGridItem";
import Header from "../../../components/Header/Header";
import Container from "reactstrap/es/Container";
import {Helmet} from "react-helmet";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            songs: [],
            total: 0,
            categoryOptions: [],
            filters: {
                categories: '',
                languages: '',
                search: ''
            },
            languageOptions: [],
            pageNumber: 1,
            loading: true,
            items: 10,
            hasMore: true
        };
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <Header/>
                <div className="main-page d-flex align-items-center">
                    <Container className="page-content">
                        <div className="card-columns home-grid">
                            {
                                homeGridItems.map((homeGridItem, key) => {
                                    return <HomeGridItem item={homeGridItem} key={key}/>;
                                })
                            }
                        </div>
                        <div className="app-signature">MB SYSTEMES SARL</div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Home;
