import React from 'react';

class MaterialIcon extends React.Component {
    render() {
        return (
            <span style={this.props.style} className={`material-icons icon `+this.props.classes}>{this.props.icon}</span>
        );
    }
}

export default MaterialIcon;
