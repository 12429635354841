import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import {Link} from "react-router-dom";

const title = 'Occupations en attente';

class OccupationList extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div className="page-occupations" id="page-occupation-list">
                            <div className="clearfix mb-4">
                                <div className="form-control-plaintext w-auto period-choice float-left">Du Mardi 20 Mars 2020 au mercredi 27 Mars 2020</div>
                                <Link to="/bedrooms" className="btn btn-primary float-right">Nouvelle occupation</Link>
                            </div>
                            <div className="occupation-container flex-fill">
                                <div className="row occupation-list">
                                    <div className="col-md-4 r-item">
                                        <Link to="/occupations/edit">
                                            <div className="card mb-3">
                                                <div className="card-body d-flex">
                                                    <div className="ri-left d-flex flex-column justify-content-between">
                                                        <span className="ri-start d-block text-center">
                                                            12 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                        <span className="ri-end d-block text-center">
                                                            13 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                    </div>
                                                    <div className="ri-right d-flex flex-fill flex-column">
                                                        <div className="flex-fill">
                                                            <span className="ri-bedroom">Chambre 103</span>
                                                            <span className="ri-formule">Nuitée (103 Simple)</span>
                                                        </div>
                                                        <div className="ri-client text-right text-warning">Frank Boris</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 r-item">
                                        <Link to="/occupations/edit">
                                            <div className="card mb-3">
                                                <div className="card-body d-flex">
                                                    <div className="ri-left d-flex flex-column justify-content-between">
                                                        <span className="ri-start d-block text-center">
                                                            12 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                        <span className="ri-end d-block text-center">
                                                            13 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                    </div>
                                                    <div className="ri-right d-flex flex-fill flex-column">
                                                        <div className="flex-fill">
                                                            <span className="ri-bedroom">Chambre 103</span>
                                                            <span className="ri-formule">Nuitée (103 Simple)</span>
                                                        </div>
                                                        <div className="ri-client text-right text-warning">Frank Boris</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 r-item">
                                        <Link to="/occupations/edit">
                                            <div className="card mb-3">
                                                <div className="card-body d-flex">
                                                    <div className="ri-left d-flex flex-column justify-content-between">
                                                        <span className="ri-start d-block text-center">
                                                            12 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                        <span className="ri-end d-block text-center">
                                                            13 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                    </div>
                                                    <div className="ri-right d-flex flex-fill flex-column">
                                                        <div className="flex-fill">
                                                            <span className="ri-bedroom">Chambre 103</span>
                                                            <span className="ri-formule">Nuitée (103 Simple)</span>
                                                        </div>
                                                        <div className="ri-client text-right text-warning">Frank Boris</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 r-item">
                                        <Link to="/occupations/edit">
                                            <div className="card mb-3">
                                                <div className="card-body d-flex">
                                                    <div className="ri-left d-flex flex-column justify-content-between">
                                                        <span className="ri-start d-block text-center">
                                                            12 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                        <span className="ri-end d-block text-center">
                                                            13 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                    </div>
                                                    <div className="ri-right d-flex flex-fill flex-column">
                                                        <div className="flex-fill">
                                                            <span className="ri-bedroom">Chambre 103</span>
                                                            <span className="ri-formule">Nuitée (103 Simple)</span>
                                                        </div>
                                                        <div className="ri-client text-right text-warning">Frank Boris</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 r-item">
                                        <Link to="/occupations/edit">
                                            <div className="card mb-3">
                                                <div className="card-body d-flex">
                                                    <div className="ri-left d-flex flex-column justify-content-between">
                                                        <span className="ri-start d-block text-center">
                                                            12 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                        <span className="ri-end d-block text-center">
                                                            13 <br/>
                                                            mar 20 <br/>
                                                            <span className="time">12:00</span>
                                                        </span>
                                                    </div>
                                                    <div className="ri-right d-flex flex-fill flex-column">
                                                        <div className="flex-fill">
                                                            <span className="ri-bedroom">Chambre 103</span>
                                                            <span className="ri-formule">Nuitée (103 Simple)</span>
                                                        </div>
                                                        <div className="ri-client text-right text-warning">Frank Boris</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default OccupationList;
