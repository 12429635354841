import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import MaterialIcon from "../../../components/Widgets/MaterialIcon";
import {Link} from "react-router-dom";
import Table from "reactstrap/es/Table";
import $ from 'jquery';
import Container from "reactstrap/es/Container";

const title = 'Planing des réservations';

class ReservationCalendar extends React.Component {

    componentDidMount() {
        $('tbody td, thead th').hover(function () {
                let t = parseInt($(this).index()) + 1;
                $('tbody td:nth-child(' + t + ')').addClass('highlighted');
                $('thead th:not(:first-child):nth-child(' + t + ')').addClass('highlighted');
            },
            function () {
                let t = parseInt($(this).index()) + 1;
                $('tbody td:nth-child(' + t + ')').removeClass('highlighted');
                $('thead th:not(:first-child):nth-child(' + t + ')').removeClass('highlighted');
            });
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div id="page-reservation-calendar">
                            <div className="header d-flex justify-content-between mb-4">
                                <div className="d-flex">
                                    <FormGroup className="mr-4">
                                        <div className="form-control">Semedi, 28 mars 2020</div>
                                        <MaterialIcon icon="event"/>
                                    </FormGroup>{' '}
                                    <Link to="/reservations/new" className="btn btn-primary"><MaterialIcon
                                        icon="add"/> Nouvelle réservation</Link>
                                </div>
                                <div>
                                    <Input placeholder="Trouver un client..."/>
                                </div>
                            </div>
                            <Table bordered id="planner-table" className="planner-table">
                                <thead>
                                <tr className="text-center">
                                    <th>Chambres</th>
                                    <th>08:00</th>
                                    <th>09:00</th>
                                    <th>10:00</th>
                                    <th>11:00</th>
                                    <th>12:00</th>
                                    <th>13:00</th>
                                    <th>14:00</th>
                                    <th>15:00</th>
                                    <th>16:00</th>
                                    <th>17:00</th>
                                    <th>18:00</th>
                                    <th>19:00</th>
                                    <th>20:00</th>
                                    <th>21:00</th>
                                    <th>22:00</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>CHAMBRE 101</th>
                                    <td>
                                        <div className="event success">Charles Dubois</div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 102</th>
                                    <td>
                                        <div className="event canceled">Charles Dubois</div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 103</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 104</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 105</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 106</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 107</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 108</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 109</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>CHAMBRE 110</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ReservationCalendar;
