import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import Col from "reactstrap/es/Col";
import {Row} from "reactstrap";
import Table from "reactstrap/es/Table";
import tablette from "../../../assets/images/tablette.png";
import hotel_logo from "../../../assets/images/default_hotel_logo.png";

const title = 'Paramètres';

class Setting extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div className="page-occupations" id="page-occupation-list">
                            <Row>
                                <Col md="6">
                                    <div className="text-center mb-4">
                                        <img height={100} src={hotel_logo} alt=""/>
                                    </div>
                                    <Table className="table-borderless">
                                        <tbody>
                                        <tr>
                                            <td className="text-right">Nom de l'hôtel :</td>
                                            <th>Mon Hotel</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Numéro contribuable :</td>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Ville :</td>
                                            <th>Yaoundé</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Adresse :</td>
                                            <th>Yaoundé</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Email :</td>
                                            <th>monhotel@gmail.com</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Téléphone :</td>
                                            <th>678452363</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Devise :</td>
                                            <th>FCFA</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Heure d'ouverture :</td>
                                            <th>08:00:00</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Moyens de paiement :</td>
                                            <th>OM, MOMO, CARTE</th>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md="6">
                                    <div className="text-center mb-4">
                                        <img height={100} src={tablette} alt=""/>
                                    </div>
                                    <Table className="table-borderless">
                                        <tbody>
                                        <tr>
                                            <td className="text-right">Marque :</td>
                                            <th>WPOS</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">IP Imprimante :</td>
                                            <th>192.168.100.180</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Numéro de série :</td>
                                            <th>Yaoundé</th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Identifiant :</td>
                                            <th>Yaoundé</th>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Setting;
