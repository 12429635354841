import React from 'react';
import CardBody from "reactstrap/es/CardBody";
import Card from "reactstrap/es/Card";
import logo from "../../assets/images/logo.png";
import logo_mb3 from "../../assets/images/logo_mb3.png";

class AppWidget extends React.Component {
    render() {
        return (
            <Card color="primary" className="app-widget">
                <CardBody>
                    <h1 className="hotel-name">Mon Hotel</h1>
                    <p className="hotel-description">
                        Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                        Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
                        Sed porttitor lectus nibh. Proin eget tortor risus. Proin eget tortor
                        risus.
                        Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                        Donec sollicitudin molestie malesuada. Donec sollicitudin molestie
                        malesuada.
                        Vivamus suscipit tortor eget felis porttitor volutpat.
                        Pellentesque in ipsum id orci porta dapibus.
                    </p>
                    <h5 className="app-message">Bienvenue sur l'interface de gestion de
                        l'hôtel</h5>
                    <Card className="app-author">
                        <CardBody>
                            <div className="d-flex justify-content-between align-items-center">
                                <img className="app-logo" src={logo} alt=""/>
                                <div className="author-details">
                                    <h5>Développé par MB SYSTEMES SARL</h5>
                                    <h5>Tel : 699990741 / 695751992</h5>
                                </div>
                                <img className="author-logo" src={logo_mb3} alt=""/>
                            </div>
                        </CardBody>
                    </Card>
                </CardBody>
            </Card>
        );
    }
}

export default AppWidget;
