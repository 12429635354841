import React from 'react';
import {Helmet} from "react-helmet";
import HeaderNav from "../../../components/Header/HeaderNav";
import Container from "reactstrap/es/Container";
import ButtonGroup from "reactstrap/es/ButtonGroup";
import Button from "reactstrap/es/Button";
import {Link} from "react-router-dom";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import CardHeader from "reactstrap/es/CardHeader";

const title = 'Facturation de l\'occupation';

class OccupationInvoice extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tchede Hotel | {title}</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <HeaderNav title={title}/>
                <div className="page d-flex align-items-center">
                    <Container className="page-content">
                        <div className="page-occupations" id="page-occupation-invoice">
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <Button color="danger">Annuler</Button>{' '}
                                    <Button color="primary">Liberer la chambre</Button>
                                </div>
                                <ButtonGroup>
                                    <Link to="/occupations/edit" className="rounded-0 btn btn-outline-secondary">Occupation</Link>
                                    <Button disabled className="rounded-0">Encaissement</Button>
                                </ButtonGroup>
                            </div>
                            <div className="page-blocks d-flex">
                                <div className="client-block">
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Code</Label>
                                        <div className="form-control-plaintext">20200234CLT1</div>
                                    </FormGroup>
                                </div>
                                <div className="payment-block d-flex flex-column">
                                    <Card className="flex-fill">
                                        <CardBody>
                                            <div className="header d-flex justify-content-between">
                                                <span>Déjà payé</span>
                                                <span>5000 Fcfa</span>
                                            </div>
                                            <ul className="payments m-0 p-0">
                                                <li className="d-flex justify-content-between">
                                                    <span className="code">CASH</span>
                                                    <span className="amount">5000</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span className="code">CASH</span>
                                                    <span className="amount">5000</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span className="code">CASH</span>
                                                    <span className="amount">5000</span>
                                                </li>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                    <div className="payments-code">
                                        <ul className="p-0 m-0 list-unstyled d-flex flex-wrap justify-content-between">
                                            <li className="payment-code"><Button outline>OM</Button></li>
                                            <li className="payment-code"><Button outline>MOMO</Button></li>
                                            <li className="payment-code"><Button outline>CASH</Button></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="cart-block flex-fill d-flex flex-column">
                                    <Card className="flex-fill">
                                        <ul className="cart-items m-0 p-0">
                                            <li className="d-flex justify-content-between header">
                                                <span>Hébergement</span>
                                                <span>5000 Fcfa</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span className="code">1 x Nuitée(Simple)</span>
                                                <span className="amount">5000</span>
                                            </li>
                                            <li className="d-flex justify-content-between header">
                                                <span>Services</span>
                                                <span>5000 Fcfa</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span className="code">Restaurant</span>
                                                <span className="amount">5000</span>
                                            </li>
                                        </ul>
                                    </Card>
                                    <div className="d-flex">
                                        <div className="text-center invoice-print flex-fill">
                                            <Button color="primary" className="mb-4">
                                                Imprimer la facture
                                            </Button><br/>
                                            <Button color="primary">
                                                Imprimer le reçu
                                            </Button>
                                        </div>
                                        <div className="invoice-amounts">
                                            <Button  outline block className="d-flex ml-auto justify-content-between">
                                                <span>TOTAL :</span>
                                                <span>5000 Fcfa</span>
                                            </Button>
                                            <Button block color="primary" disabled className="d-flex ml-auto justify-content-between">
                                                <span>Reste à  payer :</span>
                                                <span>5000 Fcfa</span>
                                            </Button>
                                            <Button block color="danger" disabled className="d-flex ml-auto justify-content-between">
                                                <span>Relicat :</span>
                                                <span>5000 Fcfa</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default OccupationInvoice;
