const clientItems = [
    {
        code: "20200221CLT1",
        name: "Frank Boris",
        stat_mat: "Célibataire",
        email: "frankboris@gmail.com",
        telephone: "697984556",
        cni_number: "1123152356",
        pays: "Cameroun",
        ville: "Yaoundé",
        adresse: "Cradat - Yaoundé"
    },
    {
        code: "20200221CLT2",
        name: "Mark Otto",
        stat_mat: "",
        email: "",
        telephone: "697984556",
        cni_number: "1123152356",
        pays: "",
        ville: "Yaoundé",
        adresse: "Cradat - Yaoundé"
    },
    {
        code: "20200221CLT3",
        name: "Jacob Thornton",
        stat_mat: "Célibataire",
        email: "jacob@gmail.com",
        telephone: "697984556",
        cni_number: "1123152356",
        pays: "Cameroun",
        ville: "Yaoundé",
        adresse: "Cradat - Yaoundé"
    },
    {
        code: "20200221CLT4",
        name: "Larry Bird",
        stat_mat: "Marié",
        email: "",
        telephone: "697984556",
        cni_number: "1123152356",
        pays: "Cameroun",
        ville: "Yaoundé",
        adresse: "Cradat - Yaoundé"
    },
    {
        code: "20200221CLT5",
        name: "Jean Baptiste",
        stat_mat: "Célibataire",
        email: "jean@gmail.com",
        telephone: "697984556",
        cni_number: "1123152356",
        pays: "Cameroun",
        ville: "Yaoundé",
        adresse: "Cradat - Yaoundé"
    }
];

export  default clientItems;